import styled from 'styled-components'
import { breakpoints } from 'src/styles/breakpoints'

export const SectionCVM = styled.section`
  display: flex;
  align-items: center;
  min-height: 477px;

  @media (min-width: ${breakpoints.md}) {
    min-height: 371px;
  }
  @media (min-width: ${breakpoints.lg}) {
    min-height: 379px;
  }
  @media (min-width: ${breakpoints.xl}) {
    min-height: 443px;
  }
`

export const CardLink = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  border: 1px solid #DEDFE4;
  box-sizing: border-box;
  border-radius: 8px;
  background: #FFFFFF;
  padding: 16px 12px 16px 19px;
  margin-top: 10px;
  
  @media (min-width: ${breakpoints.md}) {
    border-radius: 16px;
    
    &:first-child {
      margin-top: 0;
    }
    margin-top: 20px;
    padding: 18px 20px;
  }
  @media (min-width: ${breakpoints.lg}) {
    padding: 36px 20px;
  }
`
