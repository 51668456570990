import React from 'react'
import { useLocation } from '@reach/router'

import canaisJSON from './assets/data/canais.json'

import OrangeIcon from 'src/components/UI/MarkdownIcon/OrangeDsIcon'

import { SectionCVM, CardLink } from './style'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

type canaisJSONprops = {
  title: string;
  subtitle: string;
  link: string;
}

const AdequacaoCvm = () => {
  const location = useLocation()
  const [ sendDatalayerEvent ] = useDataLayer()
  const isInvestimentos = location.pathname.includes('/inter-dtvm/') || location.pathname.includes('/pra-voce/investimentos/')
  const urlLista = 'https://inter.co/pra-voce/investimentos/#Quais-sao-os-canais-de-atendimento-disponibilizados-aos-investidores-e-como-acessa-los'
  const urlCanais = 'https://inter.co/canais-de-atendimento/'

  return (
    <SectionCVM className='py-5'>
      <div className='container'>
        <div className='row d-flex align-items-center justify-content-between'>
          <div className={`col-12 col-md-6 col-lg-5 pr-xl-0 ${isInvestimentos ? 'col-xl-4' : ''}`}>
            <h3 className={`fs-24 lh-30 fw-600 text-grayscale--500 ${isInvestimentos ? 'fs-lg-40 lh-lg-50' : 'fs-lg-32 lh-lg-40'}`}>
              {isInvestimentos
                ? 'Precisa de ajuda?'
                : 'Precisa de ajuda relacionada a investimentos?'
              }
            </h3>
            <p className='fs-16 lh-19 fw-400 text-grayscale--500'>
              Além de falar com a gente pelos nossos
              <a
                href={urlCanais}
                target='_blank'
                rel='noreferrer'
                title='canais de atendimento'
                className='fw-700 text-orange--medium'
                onClick={() => {
                  sendDatalayerEvent({
                    section: 'dobra_04',
                    element_action: 'click button',
                    element_name: isInvestimentos
                    ? 'Precisa de ajuda?'
                    : 'Precisa de ajuda relacionada a investimentos?',
                    section_name: 'canais de atendimento',
                    redirect_url: urlCanais,
                  })
                }}
              > canais de atendimento
              </a>,
              você também pode entrar em contato com a <strong>CVM</strong> e com a <strong>BSM. </strong>
              Consulte também a
              <a
                href={urlLista}
                target='_blank'
                rel='noreferrer'
                title='Lista de documentos'
                className='fw-700 text-orange--medium'
                onClick={() => {
                  sendDatalayerEvent({
                    section: 'dobra_04',
                    element_action: 'click button',
                    element_name: isInvestimentos
                    ? 'Precisa de ajuda?'
                    : 'Precisa de ajuda relacionada a investimentos?',
                    section_name: ' Lista de documentos',
                    redirect_url: urlLista,
                  })
                }}
              > lista de documentos
              </a> que serão solicitados pela CVM.
            </p>
          </div>
          <div className={`col-12 ${isInvestimentos ? 'col-md-6 col-lg-7' : ' col-md-6'}`}>
            {
              canaisJSON.map((item: canaisJSONprops) => (
                <CardLink key={item.title}>
                  <div className='mr-4'>
                    <OrangeIcon size='MD' icon='link' color='#ff7a00' />
                  </div>
                  <div>
                    <h3 className='fs-18 lh-22 mb-4 m-md-0 fw-700 text-grayscale--500'>{item.title}</h3>
                    <a
                      href={item.link}
                      title={item.subtitle}
                      target='_blank'
                      rel='noreferrer'
                      className='fs-16 lh-19 fw-700 text-orange--medium'
                      onClick={() => {
                        sendDatalayerEvent({
                          section: 'dobra_04',
                          element_action: 'click button',
                          element_name: item.subtitle,
                          section_name: isInvestimentos
                          ? 'Precisa de ajuda?'
                          : 'Precisa de ajuda relacionada a investimentos?',
                          redirect_url: item.link,
                        })
                      }}
                    >
                      {item.subtitle}
                    </a>
                  </div>
                </CardLink>
              ))
            }
          </div>
        </div>
      </div>
    </SectionCVM>
  )
}

export default AdequacaoCvm
