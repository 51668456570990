import styled from 'styled-components'
import { device } from 'src/styles/breakpoints'

export const Logo = styled.img`
  max-width: 150px;

  @media ${device.tablet} {
    max-width: 156px;
  }

  @media ${device.desktopLG} {
    max-width: 135px;
  }

  @media ${device.desktopXL} {
    max-width: 168px;
  }

  @media ${device.desktopXXXL} {
    max-width: 216px;
  }
`
